/* global $ */

class App {
    bootstrap;

    constructor() {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initFontAwesome();
        this.initStickyMenu();
        this.initFooterSlider();
        this.initAos();
        //this.initSelect2();
        this.initServicesStickyMobile();
        //this.initScrollToAccordion();
        this.initMatrixBlock();
        this.initStickyPagetitle();

        $('body').css('opacity', 1);
        $(window).scroll(() => {
            this.initStickyPagetitle();
        });
        $(window).resize(() => {
            $('.pageTitle-height').height($('.service-pageTitle-section').outerHeight());
        });
        $(".card-service.hover").hover(function () {
            $(this).toggleClass("not-hover");
        });
        $(".card-service.hover .card-links li").hover(function () {
            $(this).toggleClass("not-hover");
        });
        $(".accordion-links li").hover(function () {
            $(this).toggleClass("not-hover");
        });
        $(".dropdown-menu li").hover(function () {
            $(this).toggleClass("not-hover");
        });
        $('#review_search_cat').on('change', function () {
            var frm = $(this).data('form');
            $('#' + frm).submit();
        })

        $('.show-arrow').on('click', function () {
            let $card = $(this).closest('.card-team');
            $($card).toggleClass('show');
        });

        $('.search-click').on('click', function () {
            $('.srarch-mob').toggleClass('open');
        });
    }

    getBootstrap() {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger() {
        if (window.Globals.env == 'production') {
            console.log = function () { };
        }
    }

    initFontAwesome() {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > 150) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        var _this = this;
        // var scrollCount = 0;
        var ofv = [];
        $(window).scroll(function () {
            // scrollCount++;
            if ($('.article-body').length) {
                var maxlength = $('#article_feed').attr('max-length');
                var offset = $('#article_feed').attr('current-length');
                if (offset < parseInt(maxlength)) {
                    
                    let newvalue = parseInt(offset) + 3;
                    if ($.inArray(newvalue, ofv) === -1) {
                        ofv.push(newvalue);
                        $('#article_feed').attr('current-length', newvalue);
                        _this.loadMore(ofv, newvalue);
                    }
                    
                }
            }
            stickyNav();
        });
    }

    initStickyPagetitle() {
        let scrollTop = $(window).scrollTop();
        if ($('.service-pageTitle-section').length) {
            if (scrollTop < $(".header-banner").height()) {
                $('.service-pageTitle-section').addClass('position-absolute').removeClass('position-fixed').css('top', '0');
            } else {
                $('.service-pageTitle-section').addClass('position-fixed').removeClass('position-absolute').css('top', $('.header-main').height());
            }
            if ($(window).width() < 768) {
                if (scrollTop < 0) {
                    $('.service-pageTitle-section').addClass('position-absolute').removeClass('position-fixed').css('top', '0');
                } else {
                    $('.service-pageTitle-section').addClass('position-fixed').removeClass('position-absolute').css('top', $('.header-main').height());
                }
            }
            $('.pageTitle-height').height($('.service-pageTitle-section').outerHeight());
        }
    }

    initServicesStickyMobile() {
        function checkOffset() {
            if ($(document).scrollTop() > $('.servicesNew').height() + $('#header').height()) {
                $('#offcanvasServices').css({ 'opacity': '1', 'visibility': 'visible' });
            } else {
                $('#offcanvasServices').css({ 'opacity': '0', 'visibility': 'hidden' });
            }
        }
        $(document).scroll(function () {
            checkOffset();
        });
    }

    initScrollToAccordion() {
        $( ".accordion-button" ).each(function() {
            let btn = this;
            $(btn).on("click", function(){
                setTimeout(function() {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(btn).offset().top - $(".header-main").height() - $(".service-pageTitle-section").height() - 85
                    }, 350);
                }, 350);
            });
        });
    }

    loadMore(ofv, newvalue) {
        let catId = $("select[name='search_category']").val();
        let year = $("select[name='search_year']").val();
        let word = $("input[name='search_word']").val();
        var offset = $('#article_feed').attr('current-length');
        var limit = 3;
        //console.log(ofv[ofv.length - 1]);

        $.ajax({
            url: '/ajax/getArticles',
            method: 'get',
            data: {
                offset: newvalue - 3,
                limit: limit,
                catId: catId,
                year: year,
                term: word
            }
        }).done((data) => {
            let newvalue = parseInt(offset) + parseInt(limit);
            $('#article_feed').attr('current-length', newvalue);
            let articles = $(data);
            $('#article_feed').append(articles);
        });
    }

    initForms($forms) {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts($elems) {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem);
                });
            });
        }
    }

    initTooltips($elems) {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addAlert(message, $elem, type = "danger", dismissible = false) {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false) {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }

    initFooterSlider() {
        import(/* webpackChunkName: "slick" */ './components/slick').then(() => {
            $('.footer-slider').slick({
                autoplay: false,
                arrows: true,
                slidesToShow: 6,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        });
    }

    initAos() {
        $(function () {
            var AOS = require('aos');
            AOS.init({
                offset: 100,
                duration: 700,
                easing: "ease-out-quad",
            });
            window.addEventListener('load', AOS.refresh);
            document.querySelectorAll('img')
                .forEach((img) =>
                    img.addEventListener('load', () =>
                        AOS.refresh()
                    )
                );
        });
    }

    initSelect2() {
        if ($('.form-select').length) {
            import(/* webpackChunkName: "select2" */ './components/select2').then(() => {
                $('.form-select').each(function () {
                    $(this).select2({
                        width: 'style',
                        minimumResultsForSearch: -1,
                        dropdownPosition: 'below',
                    });
                });
            });
        }
    }

    initMatrixBlock() {
        if ($('.matrix-block').length) {
            import(/* webPackChunkName: "matrixContent" */ './components/matrixblock');
        }
    }
}

export default App;
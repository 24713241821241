/**
 * Import here any npm modules and your own js/scss
 * You can import npm modules as css, scss or js
 * By importing scss you give yourself the ability to override the variables through resources.scss
 */

/**************
 * Javascript
 **************/

//App

import 'lazysizes';
import 'aos';
import App from './js/app';

window.App = new App;

/**************
 * Css
 **************/

import "bootstrap/scss/bootstrap.scss";
import 'aos/dist/aos.css';

//App
import "./css/app/base/html.scss";
import "./css/app/base/fonts.scss";
import "./css/app/base/sf-icon.scss";
import "./css/app/base/buttons.scss";
import "./css/app/base/typography.scss";
import "./css/app/components/header.scss";
import "./css/app/components/footer.scss";
import "./css/app/components/banner.scss";
import "./css/app/components/card.scss";
import "./css/app/components/testimonial.scss";
import "./css/app/components/space.scss";
import "./css/app/components/video.scss";
import "./css/app/components/nav.scss";
import "./css/app/components/article.scss";
import "./css/app/components/ourOnlinePresence.scss";
import "./css/app/components/award.scss";
import "./css/app/components/services.scss";
import "./css/app/components/twoImagePerRow.scss";
import "./css/app/components/review.scss";
import "./css/app/components/team.scss";